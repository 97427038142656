.img-1 {
  background-image: url('https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/wall-loan.jpg');
  min-height: 40vh;
}
.img-1{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /*filter: opacity(2) brightness(0.9);*/
  z-index: 10;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.section p {
  position: relative;
  top: 12px;
  font-size: 18px;
  width: 500px;
}

.section-1 {
  position: relative;
  /*z-index: 100 !important;*/
  top: 50px;
  color: #ffffff;
  font-size: 135%;
}

.section-1 h1 {
  border-radius: 15px;
  padding: 10px;
  margin: 10px;
  /*background-color: rgba(243, 243, 243, 0.63);*/
}

:root {
    --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.menu-item, .menu-item::before, .menu-item::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    list-style-type: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-size: 110%;
}

main {
    overflow: hidden;
}

.section {
    margin: 0 auto;
    padding: 6rem 0 1rem;
}

.container {
    max-width: 75rem;
    height: auto;
    margin: 0 auto;
    padding: 0 1.25rem;
}

.centered {
    text-align: center;
    vertical-align: middle;
    margin-bottom: 1rem;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: auto;
    margin: 0 auto;
    transition: all 0.35s ease;
}

.header.on-scroll {
    background: #fff;
    color: #000 !important;
    box-shadow: var(--shadow-medium);
}

.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1rem;
    width: 100%;
    height: 4.25rem;
    margin: 0 auto;
}

.brand {
    font-family: inherit;
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: -1px;
    color: #000;
    text-transform: uppercase;
}

.menu {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: auto;
    padding: 4rem 0 3rem;
    overflow: hidden;
    background-color: #a4a4a4;
    box-shadow: var(--shadow-medium);
    transition: all 0.4s ease-in-out;
}

.menu.is-active {
    top: 0;
    width: 100%;
    height: auto;
}

.menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.25rem;
}

.menu-link {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
    transition: all 0.3s ease;
}

.color-white {
    color: white;
}

.color-black {
    color: black;
}

.background-white {
    background-color: white;
}

.background-black {
    background-color: black;
}

.menu-block {
    display: inline-block;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25;
    user-select: none;
    white-space: nowrap;
    text-align: center;
    margin-left: auto;
}

@media only screen and (min-width: 48rem) {
    .menu {
        position: relative;
        top: 0;
        width: auto;
        height: auto;
        padding: 0rem;
        margin-left: auto;
        background: none;
        box-shadow: none;
    }

    .menu-inner {
        display: flex;
        flex-direction: row;
        column-gap: 2rem;
        margin: 0 auto;
    }

    .menu-link {
        text-transform: capitalize;
    }

    .menu-block {
        margin-left: 2rem;
    }
}

.burger {
    position: relative;
    display: block;
    cursor: pointer;
    user-select: none;
    order: -1;
    z-index: 10;
    width: 1.75rem;
    height: 1rem;
    border: none;
    outline: none;
    background: none;
    visibility: visible;
    transform: rotate(0deg);
    transition: 0.35s ease;
}

@media only screen and (min-width: 48rem) {
    .burger {
        display: none;
        visibility: hidden;
    }
}

.burger-line {
    position: absolute;
    display: block;
    right: 0;
    width: 100%;
    height: 2px;
    border: none;
    outline: none;
    opacity: 1;
    transform: rotate(0deg);
    /*background-color: #000;*/
    transition: 0.25s ease-in-out;
}

.burger-line:nth-child(1) {
    top: 0px;
}

.burger-line:nth-child(2) {
    top: 0.5rem;
}

.burger-line:nth-child(3) {
    top: 1rem;
}

.burger.is-active .burger-line:nth-child(1) {
    top: 0.5rem;
    transform: rotate(135deg);
}

.burger.is-active .burger-line:nth-child(2) {
    right: -1.5rem;
    opacity: 0;
    visibility: hidden;
}

.burger.is-active .burger-line:nth-child(3) {
    top: 0.5rem;
    transform: rotate(-135deg);
}

.ant-form-item {
    padding: 0px !important;
    margin: 0px !important;
    margin-bottom: 10px !important;
}

.small-text-grey{
    color: grey;
    font-size: 90%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}

.pl-20 {
    padding-left: 20px;
}

.mt-20 {
    margin-top: 20px;
}

.pt-10 {
    padding-top: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-100 {
    padding-top: 100px;
}

.card-product-title {
    font-weight: 500;
    font-size: 120%;
}

.card-product-caption {
    color: #8f8f8f;
}

.detail-product-title {
    font-weight: 500;
    font-size: 140%;
}

.detail-product-caption {
    color: #8f8f8f;
}

.detail-product-description {
    color: #5d5d5d;
}

.header-home-top {
    color: #4b4b4b;
    font-size: x-large;
    margin-top: 18px;
    margin-left: -3px;
}

.layout {
    min-height: 97vh;
    background-color: #ffffff;
}

.text-small-grey-nopadding {
    font-size: 90%;
    color: #8e8e8e;
}

.card-product {
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 3%;
    margin-right: 3%;
    box-shadow: 0px 2px 7px 2px rgb(236 236 236);
    text-align: left;
    padding: 0px;
    padding-bottom: 15px;
    min-height: 190px;
    cursor: pointer;
}

.product-img-center {
    display: flex;
    justify-content: center;
    align-items: center
}

.img-product-card {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.p-chosen-name {
    font-size: 90%;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-left: 10px;
    min-height: 25px;
}

.card-box-shadow {
    border-radius: 5px;
    margin-top: 20px;
    box-shadow: 0px 1px 5px 1px rgb(225, 225, 225);
    text-align: left;
    padding: 20px;
    min-height: 100px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 5px !important;
    font-size: 85%;
}

.ant-collapse-content-box {
    padding: 5px !important;
}

.icon-title-info {
    color: #64b48f;
    font-size: large;
}

.text-title-info {
    color: #727272;
    padding-left: 7px;
    font-size: medium;
    font-weight: 600;
}

.desktop-header-search {
    height: 50px;
    padding: 0 20px !important;
    line-height: 50px;
    background-color: #f0f2f5;
    color: #64b48f;
}

.text-small-grey-nopadding {
    font-size: 90%;
    color: #8e8e8e;
}

.ant-input-css {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
}